body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 101vh;
}

.top {
  line-height: 10em;
  padding-top: 15vh;
}

.appName,
timer {
  font-size: 50px;
}

.addUser {
  line-height: 1em;
  padding-top: 15vh;
}

.settingTimer {
  line-height: 1em;
  padding-top: 15vh;
}

.settingTurn {
  line-height: 1em;
  padding-top: 15vh;
}

.decideUser {
  line-height: 1em;
  padding-top: 15vh;
}

.decideTheme {
  line-height: 1em;
  padding-top: 15vh;
}

.talk_page {
  line-height: 1em;
  padding-top: 15vh;
}

.finish {
  line-height: 1em;
  padding-top: 20vh;
}

#timerPage {
  font-size: 50px;
}

#finishButton {
  margin-top: 300px;
}
